<template>
  <div>
    <div v-if="imagePreview" @click="closePreview" class="preview-screen">
      <img @click.stop class="preview-image" :src="imagePreview" alt="img">
    </div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br/>
        <br/>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Creative</strong>
            </div>
            <div class="text-muted">Edit creative information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="media-container">
                <img v-if="creativeFilter(creativeData.Mime) === 'image'" class="image-preview cpointer"
                     @click="imagePreview = link + creativeData.DownloadUrl" :src="link + creativeData.DownloadUrl"
                     alt="preview">
                <!-- <div v-if="creativeFilter(creativeData.Mime) === 'video'" class="image-preview" :src="creativeData.Cdn" alt="preview"> -->
                <video class="video-preview" v-if="creativeFilter(creativeData.Mime) === 'video'" autoplay controls>
                  <source :src="creativeData.Cdn" type="video/mp4">
                </video>
                <!-- </div> -->
              </div>
              <a-divider></a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Creative's name.</span>
                      </template>
                      <a-input type="text" placeholder="Creative Name"
                               v-decorator="[ 'Name', {initialValue: creativeData.Name, rules: [
                        { required: true, message: 'Name is required.' },
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Media Rating">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Creative media rating per IQG guidelines.</span>
                      </template>
                      <a-select
                        v-decorator="[ 'MediaRating', {initialValue: convertMediaRating(creativeData.MediaRating)}]"
                        class="select-menu" placeholder="Media Rating">
                        <a-select-option value="1">
                          All Audiences
                        </a-select-option>
                        <a-select-option value="2">
                          Everyone Over 12
                        </a-select-option>
                        <a-select-option value="3">
                          Mature Audiences
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Dimensions">
                    <a-input class="disabled-input" type="text" placeholder="Dimensions" @keypress.prevent
                             v-decorator="[ 'Dimensions', {initialValue: creativeData.Width + 'x' + creativeData.Height, rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                  <a-form-item v-if="creativeData.Mime === 2 || creativeData.Mime === 3" :label-col="labelCol"
                               :wrapper-col="wrapperCol" label="Ratio">
                    <a-input type="text" class="disabled-input" placeholder="Ratio" @keypress.prevent
                             v-decorator="[ 'Ratio', {initialValue: creativeData.WidthRatio + ':' + creativeData.HeightRatio, rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                  <a-form-item v-if="creativeData.Mime === 2 || creativeData.Mine === 3" :label-col="labelCol"
                               :wrapper-col="wrapperCol" label="Duration">
                    <a-input type="text" class="disabled-input" placeholder="Duration" @keypress.prevent
                             v-decorator="[ 'Duration', {initialValue: convertDuration(creativeData.Duration), rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                </div>
                <!-- video info -->
                <div class="w-100">
                  <a-form-item v-if="creativeData.Mime === 0 || creativeData.Mime === 1" :label-col="labelCol"
                               :wrapper-col="wrapperCol" label="Ratio">
                    <a-input type="text" class="disabled-input" placeholder="Ratio" @keypress.prevent
                             v-decorator="[ 'Ratio', {initialValue: creativeData.WidthRatio + ':' + creativeData.HeightRatio, rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                  <a-form-item v-if="creativeData.Mime === 2 || creativeData.Mine === 3" :label-col="labelCol"
                               :wrapper-col="wrapperCol" label="Framerate">
                    <a-input type="text" class="disabled-input" placeholder="Framerate" @keypress.prevent
                             v-decorator="[ 'Framerate', {initialValue: creativeData.Framerate + ' FPS', rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                  <a-form-item v-if="creativeData.Mime === 2 || creativeData.Mine === 3" :label-col="labelCol"
                               :wrapper-col="wrapperCol" label="Codec">
                    <a-input type="text" class="disabled-input" placeholder="Framerate" @keypress.prevent
                             v-decorator="[ 'Codec', {initialValue: creativeData.Codec, rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                  <a-form-item v-if="creativeData.Mime === 2 || creativeData.Mine === 3" :label-col="labelCol"
                               :wrapper-col="wrapperCol" label="Bitrate">
                    <a-input type="text" class="disabled-input" placeholder="Bitrate" @keypress.prevent
                             v-decorator="[ 'Bitrate', {initialValue: creativeData.Bitrate + 'kbps', rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Size">
                    <a-input type="text" class="disabled-input" placeholder="Size" @keypress.prevent
                             v-decorator="[ 'Size', {initialValue: bytesToSize(creativeData.Size), rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Type">
                    <a-input type="text" class="disabled-input" placeholder="Type" @keypress.prevent
                             v-decorator="[ 'Type', {initialValue: creativeType(creativeData.Mime), rules: [
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                  </a-form-item>
                </div>
              </div>
              <div class="ant-row ant-form-item">
                <a-divider class="mt-3"></a-divider>
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button" class="btn px-5 ml-2"
                          @click="$router.push({ name: cancelRoute(creativeData.Mime) })">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getCreatives } from '@/api/display/creative'

export default {
  components: {},
  computed: {
    ...mapState(['creative', 'settings']),
    ...mapGetters('creative', ['getCreatives']),
    creativeData: function () {
      let creativeData = this.$store.getters['creative/getCreativeData'](this.$route.params.id)
      if (typeof creativeData === 'undefined') {
        this.$store.dispatch('creative/LOAD_CREATIVES')
        creativeData = {}
      }
      console.log(creativeData)
      return creativeData
    },
    redirectLink: function () {
      if (this.creativeData.Mime === 0 || this.creativeData.Mime === 1) {
        return 'library-images'
      } else if (this.creativeData.Mime === 2 || this.creativeData.Mime === 3) {
        return 'library-videos'
      } else {
        return 'library-images'
      }
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      receivedCreatives: [],
      imagePreview: false,
      link: process.env.NODE_ENV === 'production' ? window.location.origin : 'https://dev.rtb.me',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
  },
  methods: {
    bytesToSize(bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      if (i === 0) return bytes + ' ' + sizes[i]
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
    },
    convertMediaRating(val) {
      if (val === 1) {
        return 'All Audiences'
      } else if (val === 2) {
        return 'Everyone Over 12'
      } else if (val === 3) {
        return 'Mature Audiences'
      } else {
        return undefined
      }
    },
    convertDuration(duration) {
      const minutes = duration < 60 ? '00' : parseInt(duration / 60)
      let seconds = duration % 60 ? duration % 60 : '0'
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      return `${minutes}:${seconds}`
    },
    creativeType(type) {
      if (type === 0) {
        return 'PNG'
      } else if (type === 1) {
        return 'JPEG'
      } else if (type === 2) {
        return 'MPEG4'
      } else if (type === 3) {
        return 'WEBM'
      }
    },
    closePreview() {
      this.imagePreview = false
    },
    goBack() {
      this.$router.go(-1)
    },
    cancelRoute(type) {
      if (type === 0 || type === 1) {
        return 'library-images'
      } else if (type === 2 || type === 3) {
        return 'library-videos'
      }
    },
    creativeFilter(type) {
      if (type === 0 || type === 1) {
        return 'image'
      } else if (type === 2 || type === 3) {
        return 'video'
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('creative/UPDATE_CREATIVE', {
            Id: this.creativeData.Id,
            Name: values.Name,
            MediaRating: values.MediaRating,
          }).then((status) => {
            this.$router.push({ name: this.redirectLink })
          })
        }
      })
    },
    placeholder(key) {
      return (typeof this.creativeData[key] === 'undefined') ? '' : this.creativeData[key]
    },
  },
  created() {
    getCreatives()
      .then((response) => {
        this.receivedCreatives = response
      })
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.cpointer {
  cursor: pointer;
}

.disabled-input {
  outline: none;
  border: 0px;
}

.preview-screen {
  -webkit-animation: fadein 0.2s;
  -moz-animation: fadein 0.2s;
  -ms-animation: fadein 0.2s;
  -o-animation: fadein 0.2s;
  animation: fadein 0.2s;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100001;
  min-height: 100%;
  min-width: 100%;
}

.preview-image {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  max-height: 600px;
}

.media-container {
  display: flex;
  justify-content: center;
  /* padding-top: 0px; */
  /* padding-bottom: 16px; */
}

.image-preview {
  max-width: 300px;
  max-height: 300px;
}

.video-preview {
  width: 250px !important;
  height: auto !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .video-preview {
    width: 600px !important;
    height: auto !important;
  }

  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }

  /* .video-preview {
    width: 300px !important;
    height: auto !important;
  } */
}

@media only screen and (max-width: 768px) {
  .preview-image {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    max-height: 600px;
  }

  .-m {
    margin-right: -4rem;
  }
}

@media screen
and (max-width: 320px) {
  .preview-image {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    max-height: 600px;
  }

  .image-preview {
    max-width: 250px;
    max-height: 300px;
  }
}
</style>
