var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.imagePreview)?_c('div',{staticClass:"preview-screen",on:{"click":_vm.closePreview}},[_c('img',{staticClass:"preview-image",attrs:{"src":_vm.imagePreview,"alt":"img"},on:{"click":function($event){$event.stopPropagation();}}})]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('a',{staticClass:"margin-bottom",staticStyle:{"border":"1px black"},on:{"click":_vm.goBack}},[_c('span',{staticClass:"fa fa-long-arrow-left"}),_vm._v(" Back")]),_c('br'),_c('br'),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"media-container"},[(_vm.creativeFilter(_vm.creativeData.Mime) === 'image')?_c('img',{staticClass:"image-preview cpointer",attrs:{"src":_vm.link + _vm.creativeData.DownloadUrl,"alt":"preview"},on:{"click":function($event){_vm.imagePreview = _vm.link + _vm.creativeData.DownloadUrl}}}):_vm._e(),(_vm.creativeFilter(_vm.creativeData.Mime) === 'video')?_c('video',{staticClass:"video-preview",attrs:{"autoplay":"","controls":""}},[_c('source',{attrs:{"src":_vm.creativeData.Cdn,"type":"video/mp4"}})]):_vm._e()]),_c('a-divider'),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-100 -m"},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Name"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Creative's name.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Name', {initialValue: _vm.creativeData.Name, rules: [
                      { required: true, message: 'Name is required.' },
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Name', {initialValue: creativeData.Name, rules: [\n                      { required: true, message: 'Name is required.' },\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],attrs:{"type":"text","placeholder":"Creative Name"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Media Rating"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Creative media rating per IQG guidelines.")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'MediaRating', {initialValue: _vm.convertMediaRating(_vm.creativeData.MediaRating)}]),expression:"[ 'MediaRating', {initialValue: convertMediaRating(creativeData.MediaRating)}]"}],staticClass:"select-menu",attrs:{"placeholder":"Media Rating"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" All Audiences ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" Everyone Over 12 ")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v(" Mature Audiences ")])],1)],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Dimensions"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Dimensions', {initialValue: _vm.creativeData.Width + 'x' + _vm.creativeData.Height, rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Dimensions', {initialValue: creativeData.Width + 'x' + creativeData.Height, rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Dimensions"},on:{"keypress":function($event){$event.preventDefault();}}})],1),(_vm.creativeData.Mime === 2 || _vm.creativeData.Mime === 3)?_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Ratio"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Ratio', {initialValue: _vm.creativeData.WidthRatio + ':' + _vm.creativeData.HeightRatio, rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Ratio', {initialValue: creativeData.WidthRatio + ':' + creativeData.HeightRatio, rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Ratio"},on:{"keypress":function($event){$event.preventDefault();}}})],1):_vm._e(),(_vm.creativeData.Mime === 2 || _vm.creativeData.Mine === 3)?_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Duration"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Duration', {initialValue: _vm.convertDuration(_vm.creativeData.Duration), rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Duration', {initialValue: convertDuration(creativeData.Duration), rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Duration"},on:{"keypress":function($event){$event.preventDefault();}}})],1):_vm._e()],1),_c('div',{staticClass:"w-100"},[(_vm.creativeData.Mime === 0 || _vm.creativeData.Mime === 1)?_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Ratio"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Ratio', {initialValue: _vm.creativeData.WidthRatio + ':' + _vm.creativeData.HeightRatio, rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Ratio', {initialValue: creativeData.WidthRatio + ':' + creativeData.HeightRatio, rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Ratio"},on:{"keypress":function($event){$event.preventDefault();}}})],1):_vm._e(),(_vm.creativeData.Mime === 2 || _vm.creativeData.Mine === 3)?_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Framerate"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Framerate', {initialValue: _vm.creativeData.Framerate + ' FPS', rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Framerate', {initialValue: creativeData.Framerate + ' FPS', rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Framerate"},on:{"keypress":function($event){$event.preventDefault();}}})],1):_vm._e(),(_vm.creativeData.Mime === 2 || _vm.creativeData.Mine === 3)?_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Codec"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Codec', {initialValue: _vm.creativeData.Codec, rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Codec', {initialValue: creativeData.Codec, rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Framerate"},on:{"keypress":function($event){$event.preventDefault();}}})],1):_vm._e(),(_vm.creativeData.Mime === 2 || _vm.creativeData.Mine === 3)?_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Bitrate"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Bitrate', {initialValue: _vm.creativeData.Bitrate + 'kbps', rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Bitrate', {initialValue: creativeData.Bitrate + 'kbps', rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Bitrate"},on:{"keypress":function($event){$event.preventDefault();}}})],1):_vm._e(),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Size"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Size', {initialValue: _vm.bytesToSize(_vm.creativeData.Size), rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Size', {initialValue: bytesToSize(creativeData.Size), rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Size"},on:{"keypress":function($event){$event.preventDefault();}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Type"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'Type', {initialValue: _vm.creativeType(_vm.creativeData.Mime), rules: [
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]),expression:"[ 'Type', {initialValue: creativeType(creativeData.Mime), rules: [\n                      { max: 64, message: 'Maximum 64 characters allowed.'}\n                      ]}]"}],staticClass:"disabled-input",attrs:{"type":"text","placeholder":"Type"},on:{"keypress":function($event){$event.preventDefault();}}})],1)],1)]),_c('div',{staticClass:"ant-row ant-form-item"},[_c('a-divider',{staticClass:"mt-3"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper"},[_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit"}},[_vm._v("Save Changes")]),_c('button',{staticClass:"btn px-5 ml-2",attrs:{"type":"button"},on:{"click":function($event){_vm.$router.push({ name: _vm.cancelRoute(_vm.creativeData.Mime) })}}},[_vm._v("Cancel ")])])],1)],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"cui__utils__heading mb-0"},[_c('strong',[_vm._v("Edit Creative")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Edit creative information")])])
}]

export { render, staticRenderFns }